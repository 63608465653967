import { FC, useState } from "react"

import { css, Theme } from "@emotion/react"
import { TextField } from "@material-ui/core"
import { Autocomplete, AutocompleteChangeReason } from "@material-ui/lab"
import { useThrottle } from "@react-hook/throttle"
import { useHistory, useRouteMatch } from "react-router-dom"

import { trackEvent, TrackingEvent } from "@ncs/web-legos"
import { CustomerPart, initialCustomerPartQueryParams, useCustomerParts } from "@ncs/ncs-api"
import {
	Box,
	encodeUrlState,
	Icon,
	Paragraph,
	PartImage,
	useChangeCallback,
	useUrlState,
} from "@ncs/web-legos"

import { PartSearchResultsUrlState } from "~/views/shop/part-search-results"

export type ShopBarSearchUrlState = Pick<PartSearchResultsUrlState, "search">

export const ShopBarSearchEcomm: FC = () => {
	const history = useHistory()
	const [popoverOpen, setPopoverOpen] = useState(false)
	const onSearchPage = useRouteMatch("/shop/search")

	const [{ search }, { updateUrlValue }] = useUrlState<ShopBarSearchUrlState>({
		search: initialCustomerPartQueryParams.search,
	})

	const [throttledSearchInput, setThrottledSearchInput] = useThrottle<string | null>(
		search,
		3,
		true
	)

	// We'll control the state of the autocomplete here so that we have access
	// to what's selected when you click the 'search' button.
	const [autocompleteValue, setAutocompleteValue] = useState<CustomerPart | string | null>(null)

	const { data: partResults, isLoading } = useCustomerParts({
		params: {
			search: throttledSearchInput,
		},
		queryConfig: {
			enabled: !!throttledSearchInput,
		},
		removeChildren: true,
	})

	const getOptionLabel = (option: CustomerPart | string | null) => {
		if (option == null) {
			return ""
		} else if (typeof option === "string") {
			return option
		} else {
			return `${option.title ?? ""} ${option.onlinePartNumber ?? ""}`
		}
	}

	const onSearch = (searchValue: CustomerPart | string | null) => {
		if (searchValue == null) {
			history.push("/shop/search")
		} else if (typeof searchValue === "string") {
			if (onSearchPage) {
				updateUrlValue("search", searchValue)
			} else {
				history.push({
					pathname: "/shop/search/",
					search: encodeUrlState<PartSearchResultsUrlState>({
						search: searchValue,
					}),
				})
			}
			trackEvent(TrackingEvent.SEARCH, { searchValue: searchValue })
		} else {
			history.push(`/shop/parts/${searchValue.id}`)
			trackEvent(TrackingEvent.GO_TO_PRODUCT, { id: searchValue.id })
			trackEvent(TrackingEvent.SEARCH, { searchValue: searchValue.description })
		}
	}

	const onChangeSelection = (
		option: CustomerPart | string | null,
		reason: AutocompleteChangeReason
	) => {
		// Update local state.
		setAutocompleteValue(option)

		// Do the appropriate action for the selection.
		if (reason !== "clear") {
			onSearch(option)
		}
	}

	// Listen for the part search param going to empty. This means user has dismissed the active
	// filter pill in the search results. We should also clear out the text from the Autocomplete
	// and mobile text input when this happens.
	useChangeCallback(search, (newSearch) => {
		if (!newSearch && !!throttledSearchInput) {
			setThrottledSearchInput(null)
			setAutocompleteValue(null)
		}
	})

	const placeholder = "Search Product"

	const handleSearch = () => {
		onSearch(throttledSearchInput)
	}

	return (
		<Autocomplete
			freeSolo
			fullWidth
			options={partResults}
			open={popoverOpen}
			css={autocompleteStyle}
			onClose={() => setPopoverOpen(false)}
			getOptionLabel={getOptionLabel}
			onInputChange={(e, value) => setThrottledSearchInput(value)}
			value={autocompleteValue}
			onChange={(e, option, reason) => onChangeSelection(option, reason)}
			renderInput={(params) => (
				<TextField
					{...params}
					InputProps={{
						...params.InputProps,
						disableUnderline: true,
						placeholder,
						endAdornment: (
							<Box css={iconCss} onClick={handleSearch}>
								<Icon
									icon={isLoading ? "spinner-third" : "search"}
									color="gray"
									spin={isLoading}
								/>
							</Box>
						),
					}}
				/>
			)}
			onOpen={() => {
				if (throttledSearchInput) {
					setPopoverOpen(true)
				}
			}}
			renderOption={(option) => {
				return (
					<Box display="flex" alignItems="center">
						<PartImage
							src={option.imageUrl}
							width={3}
							css={css`
								max-height: 3em;
								img {
									width: unset;
									max-height: 3em;
								}
							`}
						/>
						<Box display="flex" flexDirection="column" ml={1}>
							<Paragraph>{option.title}</Paragraph>
							<Paragraph small color="secondary">
								{option.onlinePartNumber ?
									`#${option.onlinePartNumber}`
								: option.isParent ?
									"Multiple options available"
								:	""}
							</Paragraph>
						</Box>
					</Box>
				)
			}}
		/>
	)
}

const iconCss = css`
	height: 40px;
	width: 40px;
	color: #00050a;

	display: flex;
	width: 40px;
	height: 40px;
	padding: 8px;
	padding-top: 6px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	flex-shrink: 0;

	border-radius: var(--none, 0px) 8px 8px var(--none, 0px);
	border: 0.5px solid #66696c;
	border-top: none;
	border-bottom: none;

	& svg {
		color: #00050a;
	}
`

const autocompleteStyle = (theme: Theme) => css`
	background: white;
	padding-bottom: 10px;
	border-radius: 0;
	width: 512px;
	height: 40px;
	border-radius: 0.5em;

	${theme.breakpoints.down("xs")} {
		width: 100%;
		margin-top: 10px;
	}

	${theme.breakpoints.down("sm")} {
		width: 100%;
	}

	.MuiAutocomplete-inputRoot {
		padding-right: 0;
		padding-left: 1em;

		.MuiAutocomplete-input {
			border-radius: 0;
			padding: 7px 10px;
		}
	}
`
