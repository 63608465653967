import React from "react"

export interface ChevronUpIconProps {
	color?: string
}

export const ChevronUpIcon: React.FC<ChevronUpIconProps> = ({ color, ...rest }) => {
	return (
		<span {...rest}>
			<svg
				width="12"
				height="8"
				viewBox="0 0 12 8"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					id="Primary"
					d="M6 0.427369L5.42857 0.998797L0.573957 5.85341L0 6.42737L1.14539 7.57275L1.71681 7.00133L6 2.71814L10.2832 6.9988L10.8546 7.57275L12 6.42737L11.4286 5.85594L6.57396 1.00133L6 0.427369Z"
					fill="#0B75E1"
				/>
			</svg>
		</span>
	)
}
