import React from "react"
export interface ChevronDowntIconProps {
	color?: string
}

export const ChevronDownIcon: React.FC<ChevronDowntIconProps> = ({ color, ...rest }) => {
	return (
		<span {...rest}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="12"
				height="8"
				viewBox="0 0 12 8"
				fill="none"
			>
				<path
					d="M6 7.57312L6.57143 7.00169L11.426 2.14708L12 1.57312L10.8546 0.427734L10.2832 0.999163L6 5.28235L1.71681 1.00169L1.14539 0.427734L0 1.57312L0.571429 2.14455L5.42604 6.99916L6 7.57312Z"
					fill={color ?? "black"}
				/>
			</svg>
		</span>
	)
}
