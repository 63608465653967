import React, { memo, useMemo, useState } from "react"

import { css, Theme } from "@emotion/react"

import {
	APPLICATION,
	CreditCard,
	PaymentMethod,
	StripeBankAccount,
	StripeBankAccountStatus,
	useCustomerPaymentMethods,
	useSites,
	useUserCanUse,
} from "@ncs/ncs-api"
import { titleCase } from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	Box,
	Button,
	ButtonEcomm,
	Paragraph,
	// encodeUrlState,
	LoadingSpinner,
	SquareCardForm,
	RadioGroup,
	Select,
	useChangeCallback,
} from "@ncs/web-legos"

// import { AccountTabName, AccountUrlState,  } from "~/views/account"
import {
	NewCardModalEcomm,
	NewBankAccountModalEcomm,
} from "~/views/account/components/billing-tab/components"

export interface PaymentSelectorEcommProps {
	paymentMethodValue: PaymentMethod | null
	storedCardValue: CreditCard | null
	bankAccountValue: StripeBankAccount | null
	onChangeMethod: (newValue: PaymentMethod) => void
	onChangeStoredCard: (newValue: CreditCard) => void
	onChangeBankAccount: (newValue: StripeBankAccount) => void
	hiddenMethods?: PaymentMethod[]
	onNewCard?: () => void
	onNewBankAccount?: () => void
}

export const PaymentSelectorEcomm: React.FC<PaymentSelectorEcommProps> = memo(
	({
		paymentMethodValue,
		storedCardValue,
		bankAccountValue,
		onChangeMethod,
		onChangeStoredCard,
		onChangeBankAccount,
		onNewBankAccount,
		hiddenMethods: hiddenMethodsProp = [],
		onNewCard,
	}) => {
		const canSeePaymentInfo = !useUserCanUse(APPLICATION.HidePaymentInfo)
		const [showNewCardModal, setShowNewCardModal] = useState(false)
		const [showNewBankAccountModal, setShowNewBankAccountModal] = useState(false)
		const [sites, sitesLoading] = useSites()
		const [customerPaymentMethods, loading] = useCustomerPaymentMethods()
		const cards = customerPaymentMethods?.cards ? customerPaymentMethods.cards : []
		const bankAccounts =
			customerPaymentMethods?.bankAccounts ? customerPaymentMethods.bankAccounts : []

		// When customer payment methods first come in, set the defaults as best we can.
		useChangeCallback(
			customerPaymentMethods,
			(newPaymentMethods) => {
				// Because of a Square Form bug, don't automatically switch away when it is the current value.
				if (newPaymentMethods && paymentMethodValue !== PaymentMethod.SQUARE_CARD) {
					const defaultSource = newPaymentMethods.defaultPaymentMethod?.source

					if (defaultSource === "card") {
						onChangeMethod(PaymentMethod.STORED_CARD)
						const defaultCard = newPaymentMethods.cards.find(
							(c) => c.id === newPaymentMethods.defaultPaymentMethod?.id
						)

						if (defaultCard) {
							onChangeStoredCard(defaultCard)
						} else if (newPaymentMethods.cards.length === 1) {
							onChangeStoredCard(newPaymentMethods.cards[0])
						}
					}

					if (defaultSource === "bank_account") {
						onChangeMethod(PaymentMethod.STORED_BANK_ACCOUNT)
						const defaultBankAccount = newPaymentMethods.bankAccounts.find(
							(account) => account.id === newPaymentMethods.defaultPaymentMethod?.id
						)

						if (
							defaultBankAccount &&
							defaultBankAccount.status === StripeBankAccountStatus.VERIFIED
						) {
							onChangeBankAccount(defaultBankAccount)
						} else if (newPaymentMethods.bankAccounts.length === 1) {
							onChangeBankAccount(newPaymentMethods.bankAccounts[0])
						}
					}
				}
			},
			{ callOnSetup: true }
		)

		const handleMethodChange = async (newMethod: PaymentMethod) => {
			onChangeMethod(newMethod)

			// If user chose card, check if they have only one card. If so, select it automatically.
			if (newMethod === PaymentMethod.STORED_CARD && cards.length === 1) {
				onChangeStoredCard(cards[0])
			}
			// If user chose account, check if they have only one account. If so, select it automatically,
			// as long as it's verified.
			if (
				newMethod === PaymentMethod.STORED_BANK_ACCOUNT &&
				bankAccounts.length === 1 &&
				bankAccounts[0].status === StripeBankAccountStatus.VERIFIED
			) {
				onChangeBankAccount(bankAccounts[0])
			}
		}

		const bankAccountSelectValue =
			bankAccountValue ?
				`${bankAccountValue.routingNumber}${bankAccountValue.accountNumberLast4}`
			:	null
		const storedCardSelectValue =
			storedCardValue ? Object.values(storedCardValue).join("") : null

		const hiddenMethods = useMemo(() => {
			// Hide the Bill to Account option if there are no sites.
			if (sitesLoading || (sites ?? []).length === 0) {
				return [...hiddenMethodsProp, PaymentMethod.CUSTOMER_ACCOUNT]
			} else {
				// But if there are indeed sites, then just go with whatever happens to be passed into the component.
				return hiddenMethodsProp
			}
		}, [hiddenMethodsProp, sites, sitesLoading])

		return (
			<div css={container}>
				<div css={methodPicker}>
					<RadioGroup
						htmlName="payment-method"
						options={paymentOptions.filter(
							(option) => !hiddenMethods.includes(option.value)
						)}
						value={paymentMethodValue}
						onChange={(newValue, option) => handleMethodChange(option.value)}
					/>
				</div>

				<div css={methodContent}>
					<AnimatedEntrance
						show={paymentMethodValue === PaymentMethod.STORED_BANK_ACCOUNT}
					>
						{loading ?
							<LoadingSpinner />
						:	<>
								{bankAccounts.length > 0 ?
									<Select
										label="Select an account"
										value={bankAccountSelectValue}
										options={bankAccounts}
										valueAccessor={(account) =>
											`${account.routingNumber}${account.accountNumberLast4}`
										}
										disabledAccessor={(account) =>
											account.status !== StripeBankAccountStatus.VERIFIED
										}
										textAccessor={(account) =>
											`${account.bankName} (...${
												account.accountNumberLast4
											})${
												(
													account.status !==
													StripeBankAccountStatus.VERIFIED
												) ?
													" (unverified)"
												:	""
											}`
										}
										onChange={(newValue, newAccount) => {
											if (newAccount) onChangeBankAccount(newAccount)
										}}
									/>
								:	<Paragraph color="secondary">
										Add a bank account to pay your order.
									</Paragraph>
								}
							</>
						}
					</AnimatedEntrance>

					{paymentMethodValue === PaymentMethod.STORED_BANK_ACCOUNT && (
						<AnimatedEntrance show>
							<ButtonEcomm
								variant="secondary-cta"
								onClick={() => setShowNewBankAccountModal(true)}
								containerProps={{
									mt: 2,
								}}
							>
								Add Bank Account
							</ButtonEcomm>
						</AnimatedEntrance>
					)}

					<AnimatedEntrance show={paymentMethodValue === PaymentMethod.SQUARE_CARD}>
						{loading ?
							<LoadingSpinner />
						:	<>
								<SquareCardForm />
								{cards.length > 0 ?
									<Select
										label="Select a card"
										value={storedCardSelectValue}
										options={cards}
										valueAccessor={(c) => Object.values(c).join("")}
										textAccessor={(c) =>
											`${titleCase(c.brand)} (...${c.last4})`
										}
										onChange={(newValue, newCard) => {
											if (newCard) onChangeStoredCard(newCard)
										}}
									/>
								:	<Paragraph color="secondary">No cards stored on file.</Paragraph>}

								{onNewCard && canSeePaymentInfo && (
									<Box mt={0.5}>
										<Button
											icon="plus"
											variant="secondary-cta"
											containerProps={{ mt: 1 }}
											onClick={() => setShowNewCardModal(true)}
										>
											Add Card
										</Button>
									</Box>
								)}
							</>
						}
					</AnimatedEntrance>

					{showNewCardModal && (
						<NewCardModalEcomm onClose={() => setShowNewCardModal(false)} />
					)}
					<NewBankAccountModalEcomm
						isOpen={showNewBankAccountModal}
						onClose={() => setShowNewBankAccountModal(false)}
					/>
				</div>
			</div>
		)
	}
)

const container = (theme: Theme) => css`
	display: grid;
	grid-template-columns: auto 1fr;
	${theme.breakpoints.down("xs")} {
		grid-template-columns: 1fr;
	}
`
const methodPicker = css`
	padding-right: 3rem;
	margin-bottom: 2rem;
	color: var(--Brand-Deep-Navy, #00264b);
	font-family: "Atlas Grotesk";
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px; /* 171.429% */
`
const methodContent = css`
	margin-top: 0.5rem;
	margin-bottom: 2rem;
	min-width: 20rem;
`

const paymentOptions = [
	{
		label: "Bill to Master Account",
		value: PaymentMethod.CUSTOMER_ACCOUNT,
	},
	{
		label: "Credit / Debit Card",
		value: PaymentMethod.SQUARE_CARD,
	},
	{
		label: "ACH Bank transfer",
		value: PaymentMethod.STORED_BANK_ACCOUNT,
	},
]
