import { FC, useState } from "react"

import { css, Theme } from "@emotion/react"
import { DbUserPinModal } from "~/shared-components"
import { useIsUser, UserId, useAuth } from "@ncs/ncs-api"

import {
	Box,
	Button,
	CartIcon,
	UnlockedIcon,
	EventTracker,
	Link,
	Paragraph,
	TrackingEvent,
} from "@ncs/web-legos"

import { useOrderTotals } from "~/contexts"

export const ShopBarCartSummaryEcomm: FC = () => {
	const isDb = useIsUser(UserId.DrivenBrands)
	const { isPrivilegedDbUser } = useAuth()
	const { totalItemCount } = useOrderTotals(false)
	const [showDbPinModal, setShowDbPinModal] = useState(false)

	return (
		<>
			<div css={cartStatusContainerStyle}>
				{isDb ?
					isPrivilegedDbUser ?
						<Box display="inline" css={partOnStyle}>
							<UnlockedIcon />
							<span>Parts ON</span>
						</Box>
					:	<Button
							icon="lock"
							css={partPinStyle}
							onClick={() => setShowDbPinModal(true)}
						>
							<span>Parts PIN</span>
						</Button>

				:	<Box display="inline" css={quickOrderBoxCss}>
						<EventTracker event={TrackingEvent.QUICK_ORDER}>
							<Link to="/shop/quick-order" underline={false}>
								<Paragraph css={quickOrderCss}>Quick Order</Paragraph>
							</Link>
						</EventTracker>
					</Box>
				}
				<EventTracker event={TrackingEvent.GO_TO_CART}>
					<Link to="/shop/cart-summary">
						<Box display="flex" alignItems="center">
							<div css={cartIconContainerStyle}>
								<CartIcon css={cartIconStyle} />
								{totalItemCount > 0 && (
									<div css={cartCountIndicatorStyle}>
										<span>{formatItemCount(totalItemCount)}</span>
									</div>
								)}
							</div>
						</Box>
					</Link>
				</EventTracker>
			</div>
			{showDbPinModal && <DbUserPinModal onClose={() => setShowDbPinModal(false)} />}
		</>
	)
}

const formatItemCount = (count: number): string => {
	return count > 99 ? "99+" : count.toString()
}

const cartIconStyle = (theme: Theme) => css`
	height: 36px;
	width: 36px;
	display: inline-block;
	padding-left: 6px;
	padding-top: 4px;

	path {
		fill: #00050a;
	}

	${theme.breakpoints.down("sm")} {
		path {
			fill: white;
		}
	}
`

const quickOrderBoxCss = (theme: Theme) => css`
	${theme.breakpoints.down("sm")} {
		vertical-align: super;
		font-weight: 700;
	}
	${theme.breakpoints.up("sm")} {
		height: 30px;
	}
`

const quickOrderCss = (theme: Theme) => css`
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	vertical-align: top;
	height: 26px;
	margin-right: 10px;
	color: white;

	${theme.breakpoints.up("md")} {
		color: ${theme.palette.colors.black500};
	}
`

const cartStatusContainerStyle = (theme: Theme) => css`
	margin-left: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
	a {
		text-decoration: none;
	}
	${theme.breakpoints.down("md")} {
		& * {
			color: white;
		}
	}
	${theme.breakpoints.up("md")} {
		path {
			fill: black;
		}
	}
`
const cartIconContainerStyle = (theme: Theme) => css`
	display: flex;
	align-items: center;
	position: relative;
`
const cartCountIndicatorStyle = (theme: Theme) => css`
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: -4px;
	right: -4px;
	background: ${theme.palette.error.main};
	height: 1rem;
	padding: 0 0.25rem;
	padding-right: 0.13rem;
	padding-bottom: 3px;
	border-radius: 100px;
	span {
		min-width: 1em;
		position: relative;
		top: 2px;
		color: white;
		text-align: center;
		font-feature-settings:
			"liga" off,
			"clig" off;
		font-family: "Atlas Grotesk";
		font-size: 11px;
		font-style: normal;
		font-weight: 500;
		line-height: 14.3px;
	}
`

const partPinStyle = (theme: Theme) => css`
	svg {
		margin-right: 2px;
		margin-bottom: 2px;
		width: 14px;
		height: 16px;
	}

	span {
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}

	${theme.breakpoints.down("sm")} {
		margin-right: 8px;
	}
`

const partOnStyle = (theme: Theme) => css`
	svg {
		margin-right: 8px;
		width: 18px;
		height: 16px;
		vertical-align: middle;
		margin-top: -4px;
	}

	span {
		color: #00050a;
		text-align: center;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}

	${theme.breakpoints.down("sm")} {
		padding-bottom: 5px;

		span {
			color: white;
		}

		span:last-child {
			margin-right: 8px;
		}

		path {
			fill: white;
		}
	}
`
