import React, { memo, useMemo, useState } from "react"

import { useHistory } from "react-router-dom"

import {
	CreditCard,
	PaymentMethod,
	StripeBankAccount,
	useAuth,
	useSites,
	useUserProfile,
} from "@ncs/ncs-api"
import { isCanadianProvince } from "@ncs/ts-utils"
import { Callout, encodeUrlState, ErrorText, Paragraph, useChangeCallback } from "@ncs/web-legos"

import { useShopContext } from "~/contexts"
import { PaymentSelectorEcomm } from "~/shared-components"
import { AccountTabName, AccountUrlState } from "~/views/account/Account"

//TODO: glen or sergio
export const ChoosePaymentMethodEcomm: React.FC = memo(() => {
	const auth = useAuth()
	const history = useHistory()
	const [profile, profileLoading] = useUserProfile(auth.user?.id)
	const [{ checkout }, shopDispatch] = useShopContext()
	const [userSites, userSitesLoading] = useSites()
	const [poError] = useState<string | null>(null)

	const onPaymentMethodChange = (newMethod: PaymentMethod) => {
		shopDispatch({
			type: "set checkout payment method",
			payload: newMethod,
		})
	}

	const onStoredCardChange = (newCard: CreditCard) => {
		shopDispatch({
			type: "set checkout card",
			payload: newCard,
		})
	}

	const onBankAccountChange = (newAccount: StripeBankAccount) => {
		shopDispatch({
			type: "set checkout bank account",
			payload: newAccount,
		})
	}

	const isCanada = useMemo(() => {
		return isCanadianProvince(checkout.alternateAddress?.state)
	}, [checkout.alternateAddress?.state])

	useChangeCallback(
		isCanada,
		(newIsCanada) => {
			// Whenever user has a Canadian province selected, try to change us to bill to account.
			if (newIsCanada === true) {
				shopDispatch({
					type: "set checkout payment method",
					payload: !profile?.siteCount ? null : PaymentMethod.CUSTOMER_ACCOUNT,
				})
			}
		},
		{ callOnSetup: true }
	)

	const hiddenPaymentMethods = useMemo(() => {
		// If we're loading your profile, hide them all.
		if (profileLoading) return Object.values(PaymentMethod)
		// If you don't have sites, hide bill to account.
		if (!profile?.siteCount) {
			return [PaymentMethod.CUSTOMER_ACCOUNT]
		}
		// If they're Canadian, hide everything but bill to account.
		if (isCanada) {
			return Object.values(PaymentMethod).filter(
				(method) => method !== PaymentMethod.CUSTOMER_ACCOUNT
			)
		}
		// If they're not a guest, hide stored card and square card.
		if (profile?.isCustomer) {
			return [
				PaymentMethod.STORED_CARD,
				PaymentMethod.SQUARE_CARD,
				PaymentMethod.STORED_BANK_ACCOUNT,
			]
		}
		// otherwise show them all
		return undefined
	}, [profileLoading, profile?.siteCount, isCanada, profile?.isCustomer])

	if (!userSitesLoading && userSites?.length === 0 && !checkout.alternateAddress) {
		return <Paragraph color="secondary">First select your shipping address</Paragraph>
	}

	if (!userSitesLoading && !!userSites?.length && !checkout.shipToSiteId) {
		return (
			<Paragraph color="secondary">
				{checkout.alternateAddress ?
					"First select your shipping address and which of your sites this is for"
				:	"First select your shipping address"}
			</Paragraph>
		)
	}

	return (
		<>
			<PaymentSelectorEcomm
				paymentMethodValue={checkout.selectedPaymentMethod}
				onChangeMethod={onPaymentMethodChange}
				storedCardValue={checkout.selectedCard}
				onChangeStoredCard={onStoredCardChange}
				bankAccountValue={checkout.selectedBankAccount}
				onChangeBankAccount={onBankAccountChange}
				onNewCard={() => {
					history.push({
						pathname: "/account",
						search: encodeUrlState<AccountUrlState>({
							tab: AccountTabName.Billing,
							from: "checkout",
						}),
					})
				}}
				hiddenMethods={hiddenPaymentMethods}
			/>

			{isCanada && (
				<Callout
					variant="info"
					icon="canadian-maple-leaf"
					iconFamily="brands"
					mt={-1}
					mb={2}
					maxWidth={35}
				>
					<strong>Note: </strong> All orders shipping to Canada must be charged to
					account to be invoiced later, as shipping costs are calculated manually at this
					time.
				</Callout>
			)}
			{!!poError && <ErrorText>{poError}</ErrorText>}
		</>
	)
})
