import { FC, useMemo, useState } from "react"
import {
	ButtonEcomm,
	PartPriceEcomm,
	trackEvent,
	TrackingEvent,
	useScreenSizeMatch,
} from "@ncs/web-legos"
import { css, Theme } from "@emotion/react"
import { Link, useHistory } from "react-router-dom"
import {
	CustomerPart,
	isNonParentCustomerPart,
	NonParentCustomerPart,
	useCustomerParts,
} from "@ncs/ncs-api"
import { Box, Button, Paragraph, useChangeCallback, EventTracker } from "@ncs/web-legos"
import { useOrderTotals, useShopContext } from "~/contexts"
import { PageContentWrapper, QuantityControlEcomm, RemoveFromCartModal } from "~/shared-components"
import { EmptyCartModal } from "./components"

export const CartSummaryEcomm: FC = () => {
	const history = useHistory()
	const isMobile = useScreenSizeMatch("sm")
	const [partBeingRemoved, setPartBeingRemoved] = useState<CustomerPart | null>(null)
	const [showEmptyCardModal, setShowEmptyCardModal] = useState(false)
	const [{ cart }, shopDispatch] = useShopContext()
	const { totalItemCount, cartPartsSubtotal } = useOrderTotals()

	// Feed the cart parts into the parts endpoint so we're getting back the very latest
	// price data, just in case we're coming back to this page after some time has gone
	// by and data could have gone stale.
	const { data: customerParts } = useCustomerParts({
		params: {
			onlinePartId: cart.map(({ part }) => part.id),
		},
		queryConfig: {
			enabled: cart.length > 0,
		},
		pageSize: 999, // In case there are lots of items in the cart.
	})

	// The online parts endpoint has the side effect of turning our NonParentCustomerParts into CustomerParts.
	// Flip 'em back.
	const partsData = useMemo(() => {
		const parts: NonParentCustomerPart[] = []

		customerParts.forEach((onlinePart) => {
			if (!isNonParentCustomerPart(onlinePart)) {
				throw new Error(
					"There was a parent part in the cart. This should not have happened"
				)
			} else {
				parts.push(onlinePart)
			}
		})

		return parts
	}, [customerParts])

	const changeQuantity = (quantity: number, partId: string) => {
		shopDispatch({
			type: "update part cart quantity",
			payload: {
				partId: partId ?? "",
				quantity,
			},
		})
	}

	const goToCheckout = () => {
		history.push("/shop/checkout")
		trackEvent(TrackingEvent.PROCEED_TO_CHECKOUT)
	}

	// When we get back updated parts data from the server, put that into the cart.
	useChangeCallback(partsData, (updatedPartsData) => {
		shopDispatch({
			type: "update cart parts part data",
			payload: {
				parts: updatedPartsData,
			},
		})
	})

	const partImage = (image: string, title: string) => {
		return <img src={image} alt={title} css={itemImage} />
	}

	const desktopHeader = () => (
		<div css={desktopHeaderContainer}>
			<Paragraph fontSize={1.5} color="#000" bold>
				Your cart
			</Paragraph>
			<div>
				<Paragraph fontSize={1}>
					{totalItemCount} item{totalItemCount !== 1 ? "s" : ""}
				</Paragraph>
			</div>
		</div>
	)

	const mobileHeader = () => (
		<div css={mobileHeaderContainer}>
			<Paragraph fontSize={1.5} color="#000" bold>
				Your cart
			</Paragraph>
			<div css={itemCount}>
				<Paragraph fontSize={1}>
					{totalItemCount} item{totalItemCount !== 1 ? "s" : ""}
				</Paragraph>

				{cart.length > 0 && (
					<Button css={deleteButton} onClick={() => setShowEmptyCardModal(true)}>
						Clear Cart
					</Button>
				)}
			</div>
		</div>
	)

	return (
		<PageContentWrapper
			title="Cart"
			breadcrumbs={[{ name: "Shop", to: "/shop" }, { name: "Cart" }]}
		>
			<div css={outerContainerStyle}>
				<div>
					{isMobile ? mobileHeader() : desktopHeader()}
					{totalItemCount === 0 && (
						<Paragraph color="secondary">
							There aren't any items in your cart yet.
						</Paragraph>
					)}
					{totalItemCount > 0 && (
						<div>
							{cart.map(({ part, quantity }) => (
								<div key={part.id} css={itemList}>
									<div css={item}>
										<EventTracker
											event={TrackingEvent.CARD}
											data={{ id: part.id, name: part.title }}
										>
											<Link to={`/shop/parts/${part.id}`}>
												{partImage(part.imageUrl ?? "", part.title ?? "")}
											</Link>
										</EventTracker>

										<EventTracker
											event={TrackingEvent.CARD}
											data={{ id: part.id, name: part.title }}
										>
											<Link
												to={`/shop/parts/${part.id}`}
												css={underlineHoverStyle}
											>
												<Paragraph bold fontSize={1}>
													{part.title}
												</Paragraph>
												<Paragraph css={partNumber}>
													#{part.part.partNumber}
												</Paragraph>
												<PartPriceEcomm
													netPrice={part.netPrice}
													price={part.price}
													quantity={quantity}
												/>
											</Link>
										</EventTracker>
									</div>
									<div css={quantityContainer}>
										<QuantityControlEcomm
											value={quantity}
											onChange={(newCount) =>
												changeQuantity(newCount, part.id)
											}
											onChooseZero={() => setPartBeingRemoved(part)}
											useUpdateButton
										/>
									</div>
								</div>
							))}
						</div>
					)}

					{!isMobile && (
						<>
							{cart.length > 0 && (
								<Box mt={3} textAlign="right">
									<Button
										css={deleteButton}
										onClick={() => setShowEmptyCardModal(true)}
									>
										Clear Cart
									</Button>
								</Box>
							)}
						</>
					)}
				</div>

				<div>
					<Box css={subTotal}>
						<div>
							<Paragraph css={subTotalTitle}>Subtotal</Paragraph>
							<Paragraph css={subTotalAmount}>${cartPartsSubtotal}</Paragraph>
						</div>
						<div css={divider} />
						<Button
							variant="primary-cta"
							fillContainer
							onClick={goToCheckout}
							disabled={totalItemCount < 1}
						>
							GO TO CHECKOUT
						</Button>
					</Box>
					<ButtonEcomm
						variant="text"
						customTextColor="#0b75e1"
						fillContainer
						onClick={() => history.goBack()}
						disabled={totalItemCount < 1}
					>
						Continue Shopping
					</ButtonEcomm>
				</div>
			</div>

			<EmptyCartModal
				isOpen={showEmptyCardModal}
				onClose={() => setShowEmptyCardModal(false)}
			/>
			<RemoveFromCartModal
				isOpen={!!partBeingRemoved}
				onClose={() => setPartBeingRemoved(null)}
				partId={partBeingRemoved?.id ?? null}
				partTitle={partBeingRemoved?.title ?? null}
			/>
		</PageContentWrapper>
	)
}

const outerContainerStyle = (theme: Theme) => css`
	margin-top: 1.5rem;
	display: grid;
	grid-template-columns: 1fr auto;
	${theme.breakpoints.down("sm")} {
		display: flex;
		flex-direction: column;
	}
`

const subTotal = (theme: Theme) => css`
	border-radius: 0.5rem;
	background: #fff;
	box-shadow: 0 0 0.25rem 0 #d1d5db;
	padding: 1.5rem;
	margin-left: 1.5rem;
	width: 24rem;
	margin-bottom: 1rem;

	> div {
		display: flex;
		justify-content: space-between;
	}

	${theme.breakpoints.down("sm")} {
		width: 100%;
		margin: 1rem 0 1rem 0;
	}
`

const subTotalTitle = css`
	color: #393939;
	font-size: 1.25rem;
	font-weight: 700;
	line-height: normal;
`

const subTotalAmount = css`
	color: #393939;
	font-size: 1rem;
	font-weight: 500;
	line-height: 24px;
`

const divider = css`
	border: 1px solid #eee;
	margin: 0.875rem 0;
`

const itemImage = css`
	border-radius: 0.37rem;
	border: 1px solid #e5e6e6;
	padding: 0.5rem;
	width: 5.6rem;
	height: 5.6rem;
	max-width: 5.6rem;
	max-height: 5.6rem;
	min-width: 5.6rem;
	min-height: 5.6rem;
	margin-right: 1rem;
`

const itemList = (theme: Theme) => css`
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid #e5e6e6;
	padding: 1.5rem 0;

	${theme.breakpoints.down("md")} {
		display: block;
		flex-direction: unset;
	}
`

const itemCount = css`
	justify-content: space-between;
	display: flex;
`

const item = css`
	margin-right: 1rem;
	display: flex;
`

const quantityContainer = (theme: Theme) => css`
	${theme.breakpoints.down("md")} {
		margin-top: 2rem;
	}

	div.quantitySelect,
	> div {
		width: 100%;
	}
`

const partNumber = css`
	color: #4b5563;
	font-size: 0.875rem;
	font-weight: 400;
`

const desktopHeaderContainer = (theme: Theme) => css`
	${theme.breakpoints.down("sm")} {
		display: none;
	}
`

const mobileHeaderContainer = (theme: Theme) => css`
	${theme.breakpoints.down("lg")} {
		display: block;
	}
`

const deleteButton = css`
	color: #dc2626;
	font-size: 1rem;
	font-weight: 700;
	text-decoration: underline;
`

const underlineHoverStyle = css`
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
`
