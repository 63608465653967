import React, { Dispatch, SetStateAction, useState } from "react"
import { css, Theme } from "@emotion/react"
import { useAuth, CustomerSite, UserProfile, useUserProfile } from "@ncs/ncs-api"
import { formatCurrency } from "@ncs/ts-utils"
import {
	Box,
	Card,
	Divider,
	ErrorText,
	Heading,
	Price,
	Paragraph,
	Link,
	TextInput,
	nextThread,
} from "@ncs/web-legos"
import { useShopContext } from "~/contexts"
import { OrderSummaryRow } from "~/shared-components"
import { PlaceOrderButton } from "./PlaceOrderButton"
export interface OrderSummaryEcommProps {
	isDb?: boolean
	onPlaceOrder: () => Promise<void>
	setErrorMessage: Dispatch<SetStateAction<string | null>>
	shipToSite: CustomerSite | null | undefined
	totalItemCount: number
	orderGrandTotal: number
	shipmentPartsSubtotal: number
	shippingTotal: number
	totalTax: number
	isCanada: boolean
	isSubmitting: boolean
	errorMessage: string | null
	profile: UserProfile | undefined
}
export const OrderSummaryEcomm: React.FC<OrderSummaryEcommProps> = ({
	isDb,
	onPlaceOrder,
	orderGrandTotal,
	isCanada,
	setErrorMessage,
	errorMessage,
	shipToSite,
	isSubmitting,
	totalItemCount,
	shipmentPartsSubtotal,
	shippingTotal,
	totalTax,
}) => {
	//const [poError, setPoError] = useState<string | null>(null) ?
	const auth = useAuth()
	const [, shopDispatch] = useShopContext()
	const [purchaseOrderNumber, setPurchaseOrderNumber] = useState<string | null>()
	const [profile] = useUserProfile(auth?.user?.id)
	return (
		<Card css={orderSummaryCardStyle}>
			<Heading bold mb={1}>
				Your order
			</Heading>
			<OrderSummaryRow label={`Subtotal`} amount={shipmentPartsSubtotal} />
			<Paragraph css={itemCount}>{`${totalItemCount} item${
				totalItemCount !== 1 ? "s" : ""
			}`}</Paragraph>
			<OrderSummaryRow
				label={profile?.isGuest ? "Freight(²)" : "Freight"}
				amount={
					isCanada ? `${formatCurrency(shippingTotal)} (TBD)`
					: isDb ?
						`Actual`
					:	shippingTotal
				}
			/>
			<OrderSummaryRow
				label={profile?.isGuest ? "Taxes(²)" : "Taxes"}
				amount={isCanada ? `${formatCurrency(totalTax)} (TBD)` : totalTax}
			/>
			<Divider />
			<Box display="flex" justifyContent="space-between">
				<Paragraph bold css={totalLabel}>
					Total:
				</Paragraph>
				<Price price={isDb ? orderGrandTotal - shippingTotal : orderGrandTotal} />
			</Box>
			<Divider />
			<div css={purchaseOrder}>
				<div>
					<Paragraph bold css={purchaseLabel}>
						Purchase Order
					</Paragraph>
					{profile?.isGuest ?
						<p css={purchaseOrderOptional}>(Optional)</p>
					:	<p css={purchaseOrderOptional}>(Required)</p>}
				</div>
				<TextInput
					placeholder="Enter PO#"
					ml={1}
					mb={0}
					value={purchaseOrderNumber}
					onChange={(value: string | null) => {
						setPurchaseOrderNumber(value)
						nextThread(() =>
							shopDispatch({
								type: "set purchase order",
								payload: value,
							})
						)
					}}
				/>
			</div>
			<Divider />
			<div css={disclaimerContainer}>
				{profile?.isGuest && (
					<>
						<Paragraph color="#4B5563" css={disclaimer}>
							¹ Freight is estimated and will be reconciled at time of shipping.
						</Paragraph>
						<Paragraph color="#4B5563" css={disclaimer}>
							² Taxes will be assessed at time of invoice.
						</Paragraph>
					</>
				)}
				<Paragraph color="#4B5563" css={disclaimer}>
					By clicking Place Order, you accept our {""}
					<Link color="#0B75E1" underline to="https://ncswash.com/privacy-policy/">
						Privacy Policy
					</Link>
					{""}or{""}
					<Link
						color="#0B75E1"
						underline
						to="https://ncswash.com/wp-content/uploads/2023/06/NCS-PO-STANDARD-TERMS-AND-CONDITIONS-1.pdf"
					>
						Terms and Conditions
					</Link>
					.
				</Paragraph>
			</div>
			<PlaceOrderButton
				onPlaceOrder={onPlaceOrder}
				isLoading={isSubmitting}
				setErrorMessage={setErrorMessage}
				shipToSite={shipToSite}
			/>
			{!!errorMessage && <ErrorText mb={1}>{errorMessage}</ErrorText>}
		</Card>
	)
}
const disclaimer = css`
	color: #4b5563;
	font-family: "Atlas Grotesk";
	font-size: 0.68rem;
	font-style: normal;
	font-weight: 400;
	line-height: 17px;
	margin-bottom: 0.2rem;
`
const totalLabel = css`
	color: #003264;
	font-size: 1.25rem;
	font-style: normal;
	font-weight: 400;
`
const purchaseLabel = css`
	color: #111827;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 500;
`
const disclaimerContainer = css`
	margin-bottom: 1.5rem;
`
const itemCount = css`
	color: #6b7280;
	font-size: 0.875rem;
	margin-top: -1rem;
	margin-bottom: 0.8rem;
`

const purchaseOrderOptional = css`
	color: #6b7280;
	font-size: 0.875rem;
`

const purchaseOrder = css`
	display: flex;
	align-items: center;
	justify-content: space-between;
	input {
		padding: 0.7rem;
	}
	div {
		width: 10rem;
	}
`
const orderSummaryCardStyle = (theme: Theme) => css`
	position: sticky;
	top: 1rem;
	width: 25rem;
	${theme.breakpoints.down("sm")} {
		position: relative;
		top: 0;
		width: auto;
	}
`
