import React, { useState } from "react"
import { css, Theme } from "@emotion/react"
import {
	CustomerPartQueryParams,
	initialCustomerPartQueryParams,
	usePartCategories,
	usePartChemicals,
} from "@ncs/ncs-api"
import { arrayWrap, SubTypeIncluded, toggleArrayItem } from "@ncs/ts-utils"
import {
	CheckboxGroupEcomm,
	trackEvent,
	TrackingEvent,
	useUrlState,
	ChevronDownIcon,
} from "@ncs/web-legos"
import { PartSearchResultsUrlStateEcomm } from "../../PartSearchResultsEcomm"

export const PartSearchFiltersEcomm: React.FC = () => {
	const [toggleCategories, setToggleCategories] = useState(false)
	const [toggleChemicals, setToggleChemicals] = useState(false)

	const [categories] = usePartCategories()
	const [chemicals] = usePartChemicals()

	const [partQueryParams, { setUrlState }] = useUrlState<PartSearchResultsUrlStateEcomm>(
		initialCustomerPartQueryParams,
		{
			removeKeysOnUnmount: false,
		}
	)

	// Not sure if we need this?
	const queryCategories = arrayWrap(partQueryParams.categories).map((c) => String(c))
	const queryChemicals = arrayWrap(partQueryParams.chemicals).map((c) => String(c))

	const toggleFilterCheckbox = (
		idToToggle: string,
		name: string | null,
		type: keyof SubTypeIncluded<CustomerPartQueryParams, string[]>
	) => {
		setUrlState((prev) => ({
			...prev,
			[type]: toggleArrayItem(
				idToToggle,
				arrayWrap(partQueryParams[type]).map((p) => String(p))
			),
		}))
		trackEvent(TrackingEvent.FILTERS, { id: idToToggle, name, type })
	}

	return (
		<nav>
			<ul>
				<li css={navElement}>
					<button onClick={() => setToggleCategories(!toggleCategories)}>
						<div css={category}>
							PARTS
							{toggleCategories ?
								<ChevronDownIcon color="#00050A" />
							:	<ChevronDownIcon color="#00050A" />}
						</div>
					</button>
					{toggleCategories && (
						<CheckboxGroupEcomm
							mt={0.75}
							groupName="categories"
							rows={categories ?? []}
							onChange={(category) =>
								toggleFilterCheckbox(
									String(category.id),
									category.name,
									"categories"
								)
							}
							valueAccessor={(row) => row.id.toString()}
							labelAccessor="name"
							checkedAccessor={(category) =>
								queryCategories.includes(String(category.id))
							}
						/>
					)}
				</li>
				<li css={navElement}>
					<button onClick={() => setToggleChemicals(!toggleChemicals)}>
						<div css={category}>
							CHEMICALS
							{toggleChemicals ?
								<ChevronDownIcon color="#00050A" />
							:	<ChevronDownIcon color="#00050A" />}
						</div>
					</button>
					{toggleChemicals && (
						<CheckboxGroupEcomm
							mt={0.75}
							groupName="chemicals"
							rows={chemicals ?? []}
							onChange={(chemical) =>
								toggleFilterCheckbox(
									String(chemical.id),
									chemical.name,
									"chemicals"
								)
							}
							valueAccessor={(row) => row.id.toString()}
							labelAccessor="name"
							checkedAccessor={(chemical) =>
								queryChemicals.includes(String(chemical.id))
							}
						/>
					)}
				</li>
			</ul>
		</nav>
	)
}

const navElement = (theme: Theme) => css`
	font-weight: 500;
	padding: 1.5rem 0;
	border-bottom: 1px solid #e5e7eb;

	&:first-of-type {
		margin-top: 1.5rem;
		${theme.breakpoints.down("xs")} {
			margin-top: 0;
		}
	}

	&:last-of-type {
		border-bottom: 0;
	}

	a {
		font-weight: 500;
		color: #00050a;
		text-decoration: none;
	}

	button {
		background: none;
		border: none;
		cursor: pointer;
		padding: 0;
		width: 100%;
	}
`

const category = css`
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #00050a;
	font-size: 1rem;
`
