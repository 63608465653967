import React, { useState } from "react"

import { css, Theme } from "@emotion/react"
import { Logo } from "../Logo"
import { useAuth } from "@ncs/ncs-api"
import { Box, Button, Link, useScreenSizeMatch, Icon } from "@ncs/web-legos"

import { ContentWrapperEcomm } from "../ContentWrapperEcomm"
import { ShopBarCartSummaryEcomm } from "./ShopBarCartSummaryEcomm"
import { ShopBarNavEcomm } from "./ShopBarNavEcomm"
import { ShopBarSearchEcomm } from "./ShopBarSearchEcomm"
import { ShopBarUserWelcomeEcomm } from "./ShopBarUserWelcomeEcomm"
import { MobileNavModalEcomm } from "../MobileNavModalEcomm"

export const ShopBarEcomm: React.FC = () => {
	const auth = useAuth()
	const [showNavModal, setShowNavModal] = useState(false)
	const screenIsMediumUp = useScreenSizeMatch("md", "up")

	return (
		<>
			<div css={outerContainerStyle}>
				{!!auth.user && (
					<ContentWrapperEcomm>
						<Box
							display="flex"
							alignItems="center"
							justifyContent="space-between"
							css={searchBarStyle}
						>
							{!screenIsMediumUp && (
								<Button
									onClick={() => setShowNavModal(true)}
									css={css`
										margin-bottom: 5px;
										width: 36px;
										height: 36px;

										span {
											margin-left: -4px;
											margin-top: 2px;
										}
									`}
								>
									<Icon icon="bars" color="white" fontSize={1.7} />
								</Button>
							)}
							<Link
								to="/"
								css={
									screenIsMediumUp &&
									css`
										padding-top: 4px;
									`
								}
							>
								{!screenIsMediumUp ?
									<Logo height={1.5} company="ncsWhite" />
								:	<Logo
										maxWidth={!screenIsMediumUp ? "7rem" : undefined}
										width="96px"
										company="ncsWhite"
									/>
								}
							</Link>
							{!screenIsMediumUp && <ShopBarCartSummaryEcomm />}
							{!screenIsMediumUp && <ShopBarUserWelcomeEcomm />}
							{screenIsMediumUp && (
								<>
									<ShopBarSearchEcomm css={shopBarSearchStyle} />
									<ShopBarUserWelcomeEcomm />
								</>
							)}
						</Box>
						{!screenIsMediumUp && <ShopBarSearchEcomm css={shopBarSearchStyle} />}
					</ContentWrapperEcomm>
				)}
			</div>
			{screenIsMediumUp && (
				<div css={shopBarContainerStyle}>
					<ContentWrapperEcomm>
						<Box display="flex" alignItems="center">
							<ShopBarNavEcomm />
							<ShopBarCartSummaryEcomm />
						</Box>
					</ContentWrapperEcomm>
				</div>
			)}
			<MobileNavModalEcomm isOpen={showNavModal} onClose={() => setShowNavModal(false)} />
		</>
	)
}

const searchBarStyle = (theme: Theme) => css`
	${theme.breakpoints.down("sm")} {
	}
`

const shopBarSearchStyle = css`
	padding-left: 10em;
`

const shopBarContainerStyle = css`
	background-color: #f2f2f3;
	color: black;
	width: 100%;
	box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
	position: relative;
	padding-top: 2px;

	& * {
		color: black;
	}
`

const outerContainerStyle = (theme: Theme) => css`
	background: ${theme.palette.primary.main};
	padding-top: 16px;
	padding-bottom: 16px;
	${theme.breakpoints.down("sm")} {
		padding-top: 12px;
	}
`
