import React, { useEffect, useMemo, useState } from "react"
import { css, Theme } from "@emotion/react"
import { Redirect, useParams } from "react-router-dom"
import { Tabs, trackEvent, TrackingEvent } from "@ncs/web-legos"

import {
	isNonParentCustomerPart,
	NonParentCustomerPart,
	useCustomerPart,
	useOnlinePartDetails,
	OptionVariant,
	OnlinePartDetails,
	CustomerPart,
	VariantPartOptions,
} from "@ncs/ncs-api"
import {
	Box,
	Button,
	Divider,
	EventTracker,
	encodeUrlState,
	Heading,
	Link,
	Paragraph,
	PartImage,
	PDPPriceEcomm,
	useChangeCallback,
	useScrollToTop,
	useUrlState,
} from "@ncs/web-legos"

import { useShopContext } from "~/contexts"
import {
	AddedToCartModalEcomm,
	PageContentWrapper,
	PartPreviewRowEcomm,
	QuantityControlEcomm,
} from "~/shared-components"

import { PartChildSelectorEcomm, PartDetailImagesEcomm } from "./components"

type PartDetailEcommUrlState = {
	childId: string | null
}

export interface PartDetailTabsProps {
	partDetails?: OnlinePartDetails
	initialPart?: CustomerPart
}

const PartDetailTabs: React.FC<PartDetailTabsProps> = ({ partDetails, initialPart }) => {
	const [tab, setTab] = useState("Product Details")
	const brandName = partDetails?.brands?.map((brand) => brand.brandName).join(", ")
	const categories = partDetails?.categories.map((category) => category.categoryName).join(", ")
	if (!partDetails) {
		return null
	}

	const panels = [
		{
			navLabel: "Product Details",
			component: (
				<>
					{initialPart?.description && (
						<>
							<Heading variant="h5" css={styleTabHeader}>
								Description
							</Heading>
							<div css={styleDimensionValue}>{initialPart?.description}</div>
						</>
					)}

					{brandName && (
						<>
							<Heading variant="h5" css={styleTabHeader}>
								Brand
							</Heading>
							<div css={styleDimensionValue}>{brandName}</div>
						</>
					)}
					{categories && (
						<>
							<Heading variant="h5" css={styleTabHeader}>
								Product Type
							</Heading>
							<div css={styleDimensionValue}>{categories}</div>
						</>
					)}
				</>
			),
		},
		{
			navLabel: "Weight & Dimension",
			component: (
				<Box display="flex">
					<div
						css={css`
							width: 288px;
						`}
					>
						{partDetails.part?.height && (
							<>
								<Heading variant="h5" css={styleTabHeader}>
									Height
								</Heading>
								<div css={styleDimensionValue}>{partDetails.part?.height}”</div>
							</>
						)}

						{partDetails.part?.length && (
							<>
								<Heading variant="h5" css={styleTabHeader}>
									Length
								</Heading>
								<div css={styleDimensionValue}>{partDetails.part?.length}”</div>
							</>
						)}

						{partDetails.part?.width && (
							<>
								<Heading variant="h5" css={styleTabHeader}>
									Width
								</Heading>
								<div css={styleDimensionValue}>{partDetails.part?.width}’</div>
							</>
						)}
					</div>
					<div>
						{partDetails.part?.weight && (
							<>
								<Heading variant="h5" css={styleTabHeader}>
									Weight
								</Heading>
								<div css={styleDimensionValue}>{partDetails.part?.weight} lbs</div>
							</>
						)}
						{partDetails.part?.packageSizeInGallons && (
							<>
								<Heading variant="h5" css={styleTabHeader}>
									Gallons
								</Heading>
								<div css={styleDimensionValue}>
									{partDetails.part?.packageSizeInGallons}
								</div>
							</>
						)}
					</div>
				</Box>
			),
		},
	]

	return (
		<Tabs
			tabsBarCss={css`
				font-family: "Atlas Grotesk";
				font-size: 20px;
				font-style: normal;
				font-weight: 700;
				line-height: 22px;
				text-align: center;

				.MuiTabs-indicator {
					background-color: #0b75e1;
					border-radius: 100px;
					margin-right: 15px;
					margin-left: 15px;
				}
			`}
			tabBarCss={css`
				.MuiTab-wrapper {
					text-align: center;
					padding-left: 1em;
					font-family: "Atlas Grotesk";
					font-size: 20px;
					font-style: normal;
					font-weight: 500;
					line-height: 22px; /* 110% */
				}
			`}
			panelCss={css`
				padding: 0;
				padding-top: 45px;
			`}
			currentTab={tab} // Optionally set the current tab
			onChange={setTab} // Optionally handle tab changes
			panels={panels} // Provide the panels array
		/>
	)
}

export const PartDetailEcomm: React.FC = () => {
	const scrollToTop = useScrollToTop()
	const { partId } = useParams<{ partId: string }>()
	// The part that we're immediately pointed to via the ID in the URL.
	const [initialPart, loadingInitialPart, { query }] = useCustomerPart(partId)
	const [partDetails, loadingPartDetails] = useOnlinePartDetails(partId)
	const [localQuantity, setLocalQuantity] = useState(1)
	const [showMoreInfo, setShowMoreInfo] = useState(false)
	const [shopState, shopDispatch] = useShopContext()
	const [showAddedToCartModal, setShowAddedToCartModal] = useState(false)

	const [sizeVariants, setSizeVariants] = useState<OptionVariant[]>([])
	const [colorVariants, setColorVariants] = useState<OptionVariant[]>([])
	const [caseSizeVariants, setCaseSizeVariants] = useState<OptionVariant[]>([])

	const [selectedSizeVariant, setSelectedSizeVariant] = useState<string>("")
	const [selectedColorVariant, setSelectedColorVariant] = useState<string>("")
	const [selectedCaseSizeVariant, setSelectedCaseSizeVariant] = useState<string>("")
	const unitOfMeasure = partDetails?.part?.unitOfMeasure

	const handleMoreInfo = () => setShowMoreInfo((moreInfo) => !moreInfo)

	const brandName = useMemo(
		() => partDetails?.brands.map((brand) => brand.brandName).join(", "),
		[partDetails]
	)
	// Store the selected child's ID.
	const [{ childId: selectedChildId }, { updateUrlValue }] =
		useUrlState<PartDetailEcommUrlState>({
			childId: null,
		})
	// Fetch the full CustomerPart for the selected child.
	const [selectedChildPart, loadingChildPartRaw] = useCustomerPart(selectedChildId)
	// Loading state of child part should include waiting for its parent too.
	const loadingChildPart = loadingInitialPart || loadingChildPartRaw

	/** This is the actual part we'd add to the cart after figuring out if it's
	 * a parent or not, and if so, selecting the child. */
	const selectedPart: NonParentCustomerPart | null = useMemo(() => {
		if (selectedChildPart && isNonParentCustomerPart(selectedChildPart)) {
			return selectedChildPart
		}
		if (!!initialPart && !initialPart.isParent && isNonParentCustomerPart(initialPart)) {
			return initialPart
		}

		return null
	}, [initialPart, selectedChildPart])

	const alreadyInCartTally = useMemo(() => {
		return shopState.cart.reduce(
			(prev, { part: partInCart, quantity }) =>
				partInCart.id === selectedPart?.id ? prev + quantity : prev,
			0
		)
	}, [selectedPart, shopState.cart])

	const handleVariant = (option: string, variant: string) => {
		switch (option) {
			case VariantPartOptions.Size:
				setSelectedSizeVariant(variant)
				break
			case VariantPartOptions.Color:
				setSelectedColorVariant(variant)
				break
			case VariantPartOptions.CaseSize:
				setSelectedCaseSizeVariant(variant)
				break
		}
	}

	const handleAddToCart = () => {
		if (selectedPart) {
			// Add to cart.
			shopDispatch({
				type: "add part to cart",
				payload: {
					part: selectedPart,
					quantity: localQuantity,
				},
			})
			// Show the modal.
			setShowAddedToCartModal(true)
			// Reset our local quantity.
			setLocalQuantity(1)

			trackEvent(TrackingEvent.ADD_TO_CART_PDP, {
				id: selectedPart.id,
				name: selectedPart?.part?.description,
			})
		}
	}

	// If we have child parts but no selected child, then we'll pick one by default if possible.
	useEffect(() => {
		if (
			initialPart?.isParent &&
			!selectedChildId &&
			!!partDetails &&
			partDetails.children.length > 0
		) {
			updateUrlValue("childId", partDetails.children[0].onlinePartId.toString())
		}
	}, [initialPart?.isParent, partDetails, selectedChildId, updateUrlValue])

	useEffect(() => {
		setSizeVariants(partDetails?.options?.size ?? [])
		setColorVariants(partDetails?.options?.color ?? [])
		setCaseSizeVariants(partDetails?.options?.caseSize ?? [])

		setSelectedSizeVariant(partDetails?.options?.size[0]?.variant ?? "")
		setSelectedColorVariant(partDetails?.options?.color[0]?.variant ?? "")
		setSelectedCaseSizeVariant(partDetails?.options?.caseSize[0]?.variant ?? "")
	}, [partDetails])

	// Add the part to the Recent Parts list.
	useChangeCallback(initialPart, (part) => {
		if (part && !part.isChild) {
			shopDispatch({
				type: "add part to recent parts",
				payload: part.id,
			})
		}
	})

	useEffect(() => {
		scrollToTop()
	}, [partId, scrollToTop])

	// If we came to the page with the ID of a child part in the URL, we should
	// redirect to its parent.
	if (initialPart?.isChild && partDetails?.parentId) {
		return (
			<Redirect
				to={`/shop/parts/${partDetails.parentId}${encodeUrlState<PartDetailEcommUrlState>({
					childId: initialPart.id,
				})}`}
			/>
		)
	}

	return (
		<PageContentWrapper
			title={initialPart?.title}
			breadcrumbs={[
				{ name: "Shop", to: "/shop" },
				{ name: "Search", to: "/shop/search" },
				{ name: initialPart?.title ?? "" },
			]}
			showNotFound={query.status === "error"}
		>
			<div css={partContainerStyle}>
				<Box>
					<PartImage
						imageCss={css`
							max-height: 10rem;
							height: 10rem;
							min-height: 10rem;
							max-width: 10rem;
							width: 10rem;
							min-width: 10rem;
						`}
						css={stylePartImage}
						isLoading={loadingInitialPart}
						textAlign="center"
						src={selectedPart?.imageUrl || initialPart?.imageUrl}
						alt={initialPart?.title || initialPart?.part?.description}
						skeleton={{
							size: 384,
							containerProps: {
								height: 20,
							},
						}}
					/>
					<PartDetailImagesEcomm partDetails={partDetails} setVariant={handleVariant} />
				</Box>

				<div>
					{brandName && (
						<Paragraph css={styleBrandName} isLoading={loadingPartDetails}>
							{brandName}
						</Paragraph>
					)}
					<Heading variant="h2" isLoading={loadingInitialPart} css={stylePartTitle}>
						{initialPart?.title}
					</Heading>

					<Box css={stylePartNumbers}>
						<Box
							display="flex"
							alignItems="center"
							css={css`
								width: 100%;
							`}
						>
							<div css={styleContainerOnlinePartNumber}>Part Number</div>
							<Paragraph isLoading={loadingChildPart} css={styleOnlinePartNumber}>
								{selectedPart?.onlinePartNumber}
							</Paragraph>
						</Box>

						<Box
							display="flex"
							alignItems="center"
							css={styleContainerAlternatePartNumbers}
						>
							<div
								css={css`
									width: 30%;
								`}
							>
								Alternate Part Numbers
							</div>
							<Paragraph isLoading={loadingChildPart} css={styleAlternatePartNumber}>
								{selectedPart?.part.partNumber}
							</Paragraph>
						</Box>
					</Box>

					<Box
						css={css`
							margin-top: 24px;
							margin-bottom: 34px;
						`}
					>
						<span css={styleLabelDescription}>Description</span>
						<Box d="flex">
							<Paragraph
								isLoading={loadingInitialPart}
								css={(theme: Theme) => stylePartDescription(theme, showMoreInfo)}
							>
								{initialPart?.description}
							</Paragraph>
							{!showMoreInfo &&
								initialPart?.description &&
								initialPart?.description?.length > 80 && (
									<Button onClick={handleMoreInfo}>
										<div
											css={css`
												color: var(--Brand-Cobalt, #0b75e1);
												margin-left: 1em;
												font-family: "Atlas Grotesk";
												font-size: 16px;
												cursor: pointer;
												font-style: normal;
												font-weight: 700;
												line-height: 24px;
											`}
										>
											More Info
										</div>
									</Button>
								)}
						</Box>
					</Box>

					{initialPart?.isParent && !!partDetails && (
						<>
							<PartChildSelectorEcomm
								childParts={partDetails.children}
								selectedChildId={selectedChildId}
								setSelectedChildId={(newId) => updateUrlValue("childId", newId)}
								loadingPartDetails={loadingPartDetails}
								sizeVariants={sizeVariants}
								colorVariants={colorVariants}
								caseSizeVariants={caseSizeVariants}
								selectedSizeVariant={selectedSizeVariant}
								setSelectedSizeVariant={(sizeVariant: string) =>
									setSelectedSizeVariant(sizeVariant)
								}
								selectedColorVariant={selectedColorVariant}
								setSelectedColorVariant={(colorVariant: string) =>
									setSelectedColorVariant(colorVariant)
								}
								selectedCaseSizeVariant={selectedCaseSizeVariant}
								setSelectedCaseSizeVariant={(caseSizeVariant: string) =>
									setSelectedCaseSizeVariant(caseSizeVariant)
								}
							/>
						</>
					)}

					<Paragraph isLoading={loadingInitialPart} css={styleInStock}>
						In Stock
					</Paragraph>

					<QuantityControlEcomm
						value={localQuantity}
						unitOfMeasure={unitOfMeasure}
						onChange={setLocalQuantity}
						css={styleQuantityControlEcomm}
					/>

					<Box
						css={css`
							margin-top: 48px;
							margin-bottom: 48px;
						`}
					>
						<PDPPriceEcomm
							price={selectedPart?.netPrice ?? ""}
							nonDiscountedPrice={selectedPart?.price}
							isLoading={
								loadingInitialPart || loadingChildPart || loadingPartDetails
							}
						/>
					</Box>

					<Box mt={2} mb={3}>
						<Button
							variant="primary-cta"
							width="162px"
							css={styleAddToCardContainer}
							iconFamily="solid"
							onClick={handleAddToCart}
							disabled={
								localQuantity < 1 ||
								loadingInitialPart ||
								loadingChildPart ||
								loadingPartDetails ||
								(initialPart?.isParent && !partDetails?.children.length)
							}
						>
							<span css={styleAddToCardLabel}>Add To Cart</span>
						</Button>
					</Box>

					{alreadyInCartTally > 0 && (
						<div>
							<Paragraph display="inline-block" mr={0.5}>
								You currently have {alreadyInCartTally} of this item in your cart.
							</Paragraph>
							<EventTracker event={TrackingEvent.GO_TO_CART}>
								<Link to="/shop/cart-summary" icon="shopping-cart">
									Go to cart
								</Link>
							</EventTracker>
						</div>
					)}
				</div>
			</div>

			<PartDetailTabs partDetails={partDetails} initialPart={initialPart} />

			<Divider mt={4} mb={4} />

			<PartPreviewRowEcomm
				partIds={(partDetails?.relatedParts ?? [])
					.map((p) => p.id)
					.filter((id) => id !== partId)}
				heading="Related Products"
				headingVariant="h3"
			/>

			<PartPreviewRowEcomm
				partIds={shopState.recentlyViewedPartIds.filter((id) => id !== partId)}
				heading="Recently Viewed"
				headingVariant="h3"
			/>

			<AddedToCartModalEcomm
				isOpen={showAddedToCartModal}
				partId={selectedPart?.id}
				onClose={() => setShowAddedToCartModal(false)}
			/>
		</PageContentWrapper>
	)
}

const partContainerStyle = (theme: Theme) => css`
	display: flex;
	grid-template-columns: 1fr 1fr;
	gap: 2.5rem;
	overflow-x: hidden;
	${theme.breakpoints.down("sm")} {
		grid-template-columns: 1fr;
		flex-direction: column;
	}
`

const styleAddToCardContainer = css`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	padding-top: 14px;
	padding-bottom: 18px;
	background: var(--Brand-Cobalt, #0b75e1);
	span {
		color: var(--Neutrals-White, #fff);
		text-align: right;
		font-size: 16px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		text-transform: uppercase;
	}
`

const styleAddToCardLabel = css`
	color: var(--Neutrals-White, #fff);
	text-align: right;
	font-family: "Atlas Grotesk";
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	text-transform: uppercase;
	vertical-align: -webkit-baseline-middle;
`

const styleQuantityControlEcomm = css`
	margin-top: 48px;
	select {
		width: 120px;
	}

	span {
		right: -50%;
	}
`

const styleInStock = css`
	color: #10a85f;
	font-family: "Atlas Grotesk";
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin-top: 48px;
`

const stylePartDescription = (theme: Theme, showMoreInfo = false) => css`
	color: var(--Neutrals-700, #374151);
	font-family: "Atlas Grotesk";
	font-size: 16px;
	font-style: normal;
	line-height: 24px;
	font-weight: 400;
	max-width: 40em;
	line-height: 24px; /* 150% */
	${!showMoreInfo &&
	`
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	`}
`

const styleLabelDescription = css`
	color: var(--Neutrals-900, #111827);
	font-family: "Atlas Grotesk";
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
`

const styleAlternatePartNumber = css`
	color: var(--Neutrals-700, #374151);

	/* Paragraph/Medium/Regular */
	font-family: "Atlas Grotesk";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px; /* 150% */
`

const styleContainerAlternatePartNumbers = css`
	width: 100%;
	color: var(--Neutrals-700, #374151);
	font-family: "Atlas Grotesk";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`

const styleOnlinePartNumber = css`
	color: var(--Neutrals-900, #111827);
	font-family: "Atlas Grotesk";
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
`

const styleContainerOnlinePartNumber = css`
	width: 30%;
	color: var(--Neutrals-900, #111827);
	font-family: "Atlas Grotesk";
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
`

const styleBrandName = css`
	color: var(--Brand-Navy, #003264);
	font-family: "Atlas Grotesk";
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	text-transform: uppercase;
`

const stylePartTitle = css`
	h2 {
		color: #111827;
		font-size: 24px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}
`

const stylePartImage = (theme: Theme) => css`
	border-radius: 8px;
	border: 1px solid #e5e6e6;
	background: var(--Neutrals-White, #fff);
	display: flex;
	height: 384px;
	width: 384px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-self: stretch;
	${theme.breakpoints.down("sm")} {
		width: 100%;
	}

	img {
		border-radius: 8px;
	}
`

const styleTabHeader = css`
	color: var(--Neutrals-900, #111827);
	font-family: "Atlas Grotesk";
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
`

const styleDimensionValue = css`
	margin-top: 16px;
	margin-bottom: 32px;
`

const stylePartNumbers = (theme: Theme) => css`
	width: 50em;
	margin-top: 24px;

	> div {
		margin-bottom: 10.5px;
	}

	${theme.breakpoints.down("xs")} {
		> div {
			margin-bottom: 32px;
		}

		div {
			flex-direction: column;
			width: 100%;
			margin-bottom: 8px;
		}
	}
`
