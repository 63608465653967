import React, { useEffect } from "react"
import { css } from "@emotion/react"
import { library } from "@fortawesome/fontawesome-svg-core"
import { Redirect, Switch, useHistory } from "react-router-dom"
import { UserId } from "@ncs/ncs-api"
import { ClientUpdater, faIcons as legosFaIcons, Toaster } from "@ncs/web-legos"
import { trackCustomerPortalNavigation } from "@ncs/web-legos"
import { ContentWrapper, HelpFab, Route, SiteHeader } from "~/shared-components"
import SiteFooter from "./shared-components/site-footer/SiteFooter"

import { faIcons as localFaIcons } from "~/util"
import {
	Account,
	BlueWaveWelcome,
	CartSummary,
	Checkout,
	ConnectedSiteDetail,
	ConnectedSites,
	CreateAccount,
	Dashboard,
	ForgotPassword,
	Login,
	Logout,
	MyEquipment,
	PartDetail,
	PartSearchResults,
	PunchOut,
	PunchoutOrderFinished,
	QuickOrder,
	ResetPassword,
	ServiceRequests,
	Shop,
} from "~/views"

library.add(...localFaIcons, ...legosFaIcons)

const App: React.FC = () => {
	const history = useHistory()

	useEffect(() => {
		const unlisten = history.listen((location, event: string) => {
			if (event === "REPLACE") return
			trackCustomerPortalNavigation(location.pathname)
		})

		return () => {
			unlisten()
		}
	}, [history])

	return (
		<div css={appContainer}>
			<ClientUpdater />
			<SiteHeader />
			<main id="app-main" css={main}>
				<ContentWrapper>
					<Switch>
						{/* For now, the homepage / dashboard will just redirect to the shop. */}
						<Redirect exact from="/" to="/shop" />

						{/* Authentication routes */}
						<Route
							allowAnonymous
							redirectIds={UserId.DrivenBrands}
							path="/auth/forgot-password"
							component={ForgotPassword}
						/>
						<Route
							allowAnonymous
							redirectIds={UserId.DrivenBrands}
							path="/auth/reset-password/:token"
							component={ResetPassword}
						/>
						<Route
							allowAnonymous
							redirectIfAuthenticated
							exact
							path="/auth/login"
							component={Login}
						/>
						<Route allowAnonymous exact path="/auth/logout" component={Logout} />
						<Route
							allowAnonymous
							redirectIfAuthenticated
							exact
							path="/auth/create-account"
							component={CreateAccount}
						/>

						{/* Punchout pages */}
						<Route allowAnonymous exact path="/punch-out" component={PunchOut} />
						<Route
							allowAnonymous
							exact
							path="/shop/punchout-order-finished"
							component={PunchoutOrderFinished}
						/>

						{/* BlueWave pages */}
						<Route exact path="/blue-wave" component={BlueWaveWelcome} />

						{/* The rest of the website */}
						<Route path="/dashboard" component={Dashboard} />
						<Route exact path="/shop" component={Shop} />
						<Route exact path="/shop/search" component={PartSearchResults} />
						<Route exact path="/shop/parts/:partId" component={PartDetail} />
						<Route exact path="/shop/cart-summary" component={CartSummary} />
						<Route exact path="/shop/checkout" component={Checkout} />
						<Route exact path="/shop/quick-order" component={QuickOrder} />
						<Route exact path="/connectivity" component={ConnectedSites} />
						<Route
							exact
							path="/connectivity/:siteId"
							component={ConnectedSiteDetail}
						/>
						<Route
							path="/service-requests"
							redirectIds={UserId.DrivenBrands}
							component={ServiceRequests}
						/>
						<Route
							path="/my-equipment"
							redirectIds={UserId.DrivenBrands}
							component={MyEquipment}
						/>
						<Route
							path="/account"
							redirectIds={UserId.DrivenBrands}
							component={Account}
						/>
					</Switch>
				</ContentWrapper>
			</main>
			<SiteFooter />
			<Toaster />
			<HelpFab />
		</div>
	)
}

export default App

const appContainer = css`
	display: flex;
	flex-direction: column;
	min-height: 100vh;
`

const main = css`
	flex: 1;
	background-color: #f9fafb;
	padding-bottom: 8rem;
`
